import React from "react";
import style from './SigninForm.style';
import { useTranslation } from 'react-i18next';
import Button from "../UI/Button/Button";
import { connect } from "react-redux";
import {
  setPasswordAction,
  showPasswordAction,
  loginUserEmailAction,
  resetPasswordAction,
} from "actions/user";
import { onChange } from "helper";
import ShowIcon from "./ShowIcon";
import { addStyle } from "helper";

const SigninForm = (props) => {
  const {
    setPasswordAction,
    email,
    password,
    passwordType,
    passwordReset,
    error,
    loginUserEmailAction,
    showPasswordAction,
    resetPasswordAction,
  } = props;

    const { t } = useTranslation();


    return (
    <div className={style.signinForm}>
      <p className={style.signinForm__title}>{t('signInToYourAcc')}</p>
      <p className={style.signinForm__email}>{email}</p>
      <input
        type={passwordType}
        name="password"
        className={addStyle(
          style.signinForm__input,
          style.signinForm__err,
          error.show && error.type === "login"
        )}
        placeholder={t('password')}
        onChange={onChange(setPasswordAction)}
        value={password}
      />
      <button
        onMouseDown={() => showPasswordAction("text")}
        onMouseLeave={() => showPasswordAction("password")}
        className={style.signinForm__showBtn}
      >
        {password ? <ShowIcon /> : null}
      </button>
      <Button type="signin" onClick={loginUserEmailAction}>
          {t('signIn')}
      </Button>
      {!passwordReset ? (
        <button
          onClick={resetPasswordAction}
          className={style.signinForm__reset_btn}
        >
            {t('forgotText')}
        </button>
      ) : (
        <p className={style.signinForm__reset_text}>
            {t('forgotGuide')}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    user: { email, password, passwordType, passwordReset },
    error,
  } = state;

  return {
    email,
    password,
    passwordType,
    error,
    passwordReset,
  };
};

const mapDispatchToProps = {
  setPasswordAction,
  showPasswordAction,
  loginUserEmailAction,
  resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
