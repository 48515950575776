import {
    createReducer
} from '../common';
import {
    SELECT_PLAN,
    GET_BT_TOKEN,
    SET_COUPON,
    SET_NONCE,
    SET_MENU,
    SET_DISCOUNTED_PRICE,
    APPLY_COUPON,
    RESET_COUPON,
    SET_NO_TRIAL,
    SET_CONSENT,
    SET_CAPTCHA_TOKEN,
    SET_USER_LANG
} from 'actions/main';

const formatLang = () => {
    const browserLang = window.navigator.language;

    if (browserLang.includes('-')) {
        const lastChars = browserLang.split('-')[1].toUpperCase();
        const firstChars = browserLang.split('-')[0];
        return firstChars + '_' + lastChars;
    }

    return browserLang;
};

const formattedLang = formatLang();


const initState = {
    userLang: formattedLang,
    plan: 'default',
    noTrial: null,
    coupon: '',
    isCouponApplied: false,
    btToken: '',
    nonce: '',
    paymentType: null,
    count: 1,
    isMenuShown: true,
    isShippingShown: false,
    discountedPrice: null,
    discountAmount: null,
    isConsentGiven: null
};

const selectPlan = (state, {
    payload
}) => {
    return {
        ...state,
        plan: payload
    };
};

const setNoTrial = (state, {
    payload
}) => {
    return {
        ...state,
        noTrial: payload
    };
};



const setBtToken = (state, {
    payload
}) => {
    return {
        ...state,
        btToken: payload,
    };
};

const setCoupon = (state, {
    payload
}) => {
    return {
        ...state,
        coupon: payload,
    };
};

const setApplied = (state, {
    payload
}) => {

    return {
        ...state,
        isCouponApplied: payload,
    };
};

const setNonce = (state, {
    payload
}) => {
    const {type, nonce} = payload;
    return {
        ...state,
        nonce: nonce,
        paymentType: type,
    };
};


const setDiscount = (state, {
    payload
}) => {
    return {
        ...state,
        ...payload,
    };
};

const setMenu = (state, {
    payload
}) => {
    return {
        ...state,
        isMenuShown: payload,
        ...payload,
    };
};

const setConsentData = (state, { payload }) => {
    
    return {
        ...state,
        isConsentGiven: payload,
    };
};

const setUserLang = (state, { payload }) => {

    return {
        ...state,
        userLang: payload,
    };
};

const setCaptchaToken = (state, {payload}) => {


    const {type, token} = payload;

    if (type === 'braintree') {
        return {
            ...state,
            captchaTokenBrainTree: token,
        };
    }

    if (type === 'signup') {
        return {
            ...state,
            captchaTokenSignup: token,
        };
    }
};


const handlers = {
    [SELECT_PLAN]: selectPlan,
    [SET_CAPTCHA_TOKEN]: setCaptchaToken,
    [GET_BT_TOKEN.SUCCESS]: setBtToken,
    [SET_COUPON]: setCoupon,
    [SET_NONCE]: setNonce,
    [SET_MENU]: setMenu,
    [SET_DISCOUNTED_PRICE]: setDiscount,
    [APPLY_COUPON.SUCCESS]: setApplied,
    [RESET_COUPON]: setApplied,
    [SET_NO_TRIAL]: setNoTrial,
    [SET_CONSENT]: setConsentData,
    [SET_USER_LANG]: setUserLang,

};

export default createReducer(initState, handlers);