// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header-style__header--3JncB{max-width:1200px;height:auto;margin:0 auto;padding:10px 12px}.Header-style__header__logo--3Eirp{display:none}@media screen and (min-width: 768px){.Header-style__header__logo--3Eirp{display:block}}.Header-style__header_withNav--3pm1D{max-width:1200px;margin:0 auto;padding:10px 12px;display:flex;flex-direction:column}.Header-style__header_withNav--3pm1D svg{width:40px;height:50px;margin-bottom:16px}@media screen and (min-width: 768px){.Header-style__header_withNav--3pm1D svg{width:52px;height:66px}}@media screen and (min-width: 768px){.Header-style__header_withNav--3pm1D{margin-bottom:5px;padding-top:15px;flex-direction:row;justify-content:space-between}}@media screen and (min-width: 1500px){.Header-style__header_withNav--3pm1D{margin-bottom:50px;padding-top:40px}}.Header-style__header--3JncB svg{width:40px;height:50px;margin-bottom:16px}@media screen and (min-width: 768px){.Header-style__header--3JncB svg{width:52px;height:66px}}\n", ""]);
// Exports
exports.locals = {
	"header": "Header-style__header--3JncB",
	"header__logo": "Header-style__header__logo--3Eirp",
	"header_withNav": "Header-style__header_withNav--3pm1D"
};
module.exports = exports;
