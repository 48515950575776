// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalPopup-style__modalPopup--2lp2A{width:auto !important;max-width:100%;height:auto !important;margin:0 auto;padding:0 15px;box-sizing:border-box;background:#ffffff;border-radius:16px !important;border:none !important}@media screen and (max-width: 374px){.ModalPopup-style__modalPopup--2lp2A{width:290px}}.ModalPopup-style__modalPopup__body--3ayT7{display:flex;flex-direction:column;align-items:center;justify-content:space-between;text-align:center}.ModalPopup-style__modalPopup__close_btn--21Y6A{width:30px;height:40px;margin-left:auto;position:absolute;right:20px;cursor:pointer;padding:10px;z-index:100;background-color:transparent;border:none}.ModalPopup-style__modalPopup__close_btn--21Y6A span{font-size:1.5rem;font-weight:600;line-height:1;color:#000;text-shadow:0 1px 0 #fff}.ModalPopup-style__modalPopup__close_btn--21Y6A:focus{outline:none}.ModalPopup-style__modalPopup__backdrop--36gWg{background-color:rgba(50,50,50,0.4) !important}\n", ""]);
// Exports
exports.locals = {
	"modalPopup": "ModalPopup-style__modalPopup--2lp2A",
	"modalPopup__body": "ModalPopup-style__modalPopup__body--3ayT7",
	"modalPopup__close_btn": "ModalPopup-style__modalPopup__close_btn--21Y6A",
	"modalPopup__backdrop": "ModalPopup-style__modalPopup__backdrop--36gWg"
};
module.exports = exports;
