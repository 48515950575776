import { createAsyncAction, createAction } from '../common';

export const BUY_PLAN = createAsyncAction('buyPlan');
export const buyPlanAction = (payload) => {
    return {
        type: BUY_PLAN.REQUEST,
        payload,
    };
};

export const SET_TOTAL = 'setTotal';
export const setTotalAction = createAction(SET_TOTAL);

export const SET_SUBSC_ID = 'setSubscId';
export const setSubscIdAction = createAction(SET_SUBSC_ID);

