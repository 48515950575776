// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SigninForm-style__signinForm--1nfFA{width:300px;display:flex;flex-direction:column;align-items:center;box-sizing:border-box}@media screen and (min-width: 768px){.SigninForm-style__signinForm--1nfFA{width:460px}}.SigninForm-style__signinForm__title--1nitS{font-weight:600;font-size:24px;line-height:33px;color:#000;padding-left:20px;box-sizing:border-box}.SigninForm-style__signinForm__email--1tttX{width:95%;height:42px;padding:11px 7px 7px;margin-bottom:16px;font-weight:600;font-size:14px;line-height:16px;color:#9c9c9c;border:1px solid #d8d8d8;box-sizing:border-box;border-radius:4px;text-align:left}.SigninForm-style__signinForm__input--14Kym{width:95%;height:42px;padding:4px 7px;margin-bottom:16px;font-weight:300;font-size:14px;line-height:16px;color:#323232;border:1px solid #d8d8d8;box-sizing:border-box;border-radius:4px}.SigninForm-style__signinForm__input--14Kym:focus{outline:none;border-color:#333}.SigninForm-style__signinForm__reset_btn--3EFM0{font-weight:600;font-size:16px;line-height:22px;color:#0d9477;background-color:transparent;border:none;margin:0 auto}.SigninForm-style__signinForm__reset_btn--3EFM0:focus{outline:none}.SigninForm-style__signinForm__reset_text--3ePlY{font-size:14px;font-weight:300;color:#000;text-align:center;padding-left:40px;padding-right:40px}.SigninForm-style__signinForm__showBtn--sMHLm{border:none;background-color:transparent;width:40px;position:absolute;right:30px;top:176px}@media screen and (min-width: 768px){.SigninForm-style__signinForm__showBtn--sMHLm{right:33px}}.SigninForm-style__signinForm__showBtn--sMHLm:hover path{fill:#00bfa5}.SigninForm-style__signinForm__showBtn--sMHLm:focus{outline:none}.SigninForm-style__signinForm__err--1qtDO{border:1.2px solid #f3364e}\n", ""]);
// Exports
exports.locals = {
	"signinForm": "SigninForm-style__signinForm--1nfFA",
	"signinForm__title": "SigninForm-style__signinForm__title--1nitS",
	"signinForm__email": "SigninForm-style__signinForm__email--1tttX",
	"signinForm__input": "SigninForm-style__signinForm__input--14Kym",
	"signinForm__reset_btn": "SigninForm-style__signinForm__reset_btn--3EFM0",
	"signinForm__reset_text": "SigninForm-style__signinForm__reset_text--3ePlY",
	"signinForm__showBtn": "SigninForm-style__signinForm__showBtn--sMHLm",
	"signinForm__err": "SigninForm-style__signinForm__err--1qtDO"
};
module.exports = exports;
