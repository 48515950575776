// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Card-style__card--2CQdz{width:328px;height:345px;margin-bottom:24px;padding:25px 17px 17px;box-sizing:border-box;background-color:#ffffff;box-shadow:0px 4px 24px rgba(0,0,0,0.16);border-radius:25px;display:flex;flex-direction:column;align-items:center;justify-content:space-between}@media screen and (max-width: 374px){.Card-style__card--2CQdz{width:300px}}@media screen and (min-width: 768px){.Card-style__card--2CQdz{width:358px;height:348px}}@media screen and (min-width: 1140px){.Card-style__card--2CQdz:nth-child(2n){order:2}}.Card-style__main--y2Z3O{position:relative;height:373px;border:2px solid #f2994a}@media screen and (min-width: 768px){.Card-style__main--y2Z3O{width:358px;height:406px}}.Card-style__main--y2Z3O::before{content:\"Exclusive deal\";position:absolute;width:145px;height:24px;font-weight:600;color:#fff;font-size:12px;background-color:#f2994a;border-radius:1000px;text-align:center;padding:4px 0 3px;box-sizing:border-box;top:-12px;left:50%;margin-left:-70px}.Card-style__summary--3fhDT{width:100%;height:auto;padding:14px;align-items:initial;border-radius:10px}@media screen and (min-width: 768px){.Card-style__summary--3fhDT{padding:30px 24px 27px}}@media screen and (min-width: 1140px){.Card-style__summary--3fhDT:nth-child(2n){order:unset}}\n", ""]);
// Exports
exports.locals = {
	"card": "Card-style__card--2CQdz",
	"main": "Card-style__main--y2Z3O",
	"summary": "Card-style__summary--3fhDT"
};
module.exports = exports;
