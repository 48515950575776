import React from 'react';
import style from './Language.style';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {setUserLangAction} from 'actions/main';


const Language = ({t, i18n, setUserLangAction, userLang}) => {

    const onLangChange = value => {
        setUserLangAction(value);
        i18n.changeLanguage(value);
    };

    return (
        <div className={style.Select}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id=""
                 className="SVG SVG--inline c-bw-12 mr-4" preserveAspectRatio="xMidYMid meet">
                <path
                    d="M12.005 0C18.63.003 24 5.374 24 12c0 6.525-5.209 11.834-11.695 11.996l-.331.004C5.359 23.986 0 18.619 0 12 0 5.475 5.209.166 11.695.004l.31-.004zM11 13H7.031c.224 3.529 1.636 6.452 3.97 8.218L11 13zm5.969.001L13 13v8.219c2.253-1.706 3.647-4.49 3.941-7.855L16.97 13zm-14.92 0a10.008 10.008 0 006.042 8.207C6.276 19.088 5.209 16.234 5.028 13H2.049zm19.902 0h-2.979c-.181 3.233-1.248 6.087-3.061 8.208a10.014 10.014 0 006.003-7.892l.037-.316zm0-2a10.008 10.008 0 00-6.042-8.207c1.815 2.12 2.882 4.974 3.063 8.208L21.951 11zM8.089 2.792l-.042.02A10.008 10.008 0 002.049 11h2.979c.181-3.233 1.248-6.087 3.061-8.208zM7.031 11H11V2.782C8.748 4.487 7.353 7.27 7.06 10.637L7.03 11zm5.97-8.218L13 11h3.969C16.745 7.472 15.333 4.549 13 2.782z"
                    fill="#232323" fillRule="evenodd"/>
            </svg>

            <select className={style.Select__input} onChange={(evt) => onLangChange(evt.target.value)} >
                <option value='' selected disabled={true}>{t('lang.switch_lang')}</option>
                <option value='en'>{t('lang.eng')}</option>
                <option value='ru'>{t('lang.ru')}</option>
                <option value='pt'>{t('lang.pt')}</option>
                <option value='es'>{t('lang.es')}</option>
            </select>

        </div>

    )
};

const mapStateToProps = ({ main  }) => {
    return {
        ...main,
    };
};
const mapDispatchToProps = {
    setUserLangAction
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Language));