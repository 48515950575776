import React from "react";
import style from "./NotFound.style";
import ErrorImg from "./ErrorImg";

const NotFound = (props) => {
  return (
    <div className="container">
      <div className={style.notfound}>
        <ErrorImg />
        <p className={style.notfound__text}>Page not found</p>
      </div>
    </div>
  );
};

export default NotFound;
