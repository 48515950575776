// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Coupon-style__coupon--3Poq4{position:relative}.Coupon-style__coupon__input--2kYhI{width:100%;font-weight:300;height:42px;background:#ffffff;border:1px solid #d8d8d8;box-sizing:border-box;border-radius:4px;font-size:16px;line-height:22px;color:#000000;display:block;padding:5px 5px 5px 10px;margin:16px 0}@media (max-width: 1024px){.Coupon-style__coupon__input--2kYhI{font-size:13px;line-height:15px}}@media (max-width: 374px){.Coupon-style__coupon__input--2kYhI{font-size:10px}}.Coupon-style__coupon__input--2kYhI:focus{outline:none;border-color:#000000}.Coupon-style__coupon__input--2kYhI::placeholder{font-size:14px;line-height:20px;color:#9c9c9c}.Coupon-style__coupon__input_applied--3u-k9{opacity:0.6}.Coupon-style__coupon__input--err--3jI8A{border:1.2px solid #f3364e}.Coupon-style__coupon__btn--1jwJK{border:none;margin:2px;font-size:14px;display:initial;position:absolute;bottom:calc(100% - 34px);right:10px;color:#23cba7;background-color:transparent;font-weight:600}.Coupon-style__coupon__btn--1jwJK:hover{color:#408878}.Coupon-style__coupon__btn--1jwJK:focus{outline:none}.Coupon-style__coupon__btn--1jwJK:disabled{color:#e0e0e0}\n", ""]);
// Exports
exports.locals = {
	"coupon": "Coupon-style__coupon--3Poq4",
	"coupon__input": "Coupon-style__coupon__input--2kYhI",
	"coupon__input_applied": "Coupon-style__coupon__input_applied--3u-k9",
	"coupon__input--err": "Coupon-style__coupon__input--err--3jI8A",
	"coupon__btn": "Coupon-style__coupon__btn--1jwJK"
};
module.exports = exports;
