import { createReducer } from '../common';
import { SET_STEP_STATE } from '../actions/steps';
const initState = {
    1: {
        label: 'home',
        active: false,
    },
    2: {
        label: 'planSelection',
        active: true,
    },
    3: {
        label: 'paymentCompletion',
        active: false,
    },
};

const setStepState = (state, { payload }) => {
    const { step, active } = payload;
    return {
        ...state,
        [step]: {
            ...state[step],
            active,
        },
    };
};

const handlers = {
    [SET_STEP_STATE]: setStepState,
};

export default createReducer(initState, handlers);
