import { put, call, select, takeEvery } from 'redux-saga/effects';
import { BUY_PLAN, SET_SUBSC_ID } from 'actions/checkout';
import API from 'api';
import { SPINNER, MODAL } from 'actions/popup';
import { gaUserId } from '../../analytics';


function* buy() {
    const {
        main: {
            plan,
            nonce,
            coupon,
            isCouponApplied
        },
        user: {
            token,
            appsflyerId
        }
    } = yield select();

    const res = {};

    yield put({
        type: SPINNER,
    });

    let slug;

    switch (plan) {
    case 'yearly':
        slug = '1-year-plan-apr';
        break;
    case 'monthly':
        slug = 'monthly-premium-subscription-a';
        break;
    case 'quarterly':
        slug = 'gz-3-month-no-trial-plan';
        break;
    default:
        slug = 'yearly';
    }


    try {


        const params = {
            token,
            payment_method_nonce: nonce,
            google_client_id: gaUserId(),
            appsflyer_id: appsflyerId,
            slug
        };


        isCouponApplied && Object.assign(params, {
            ...params,
            coupon
        });

        const resSunscribe = yield call(API.buySubscription, params);
        Object.assign(res, resSunscribe);


        yield put({
            type: SET_SUBSC_ID,
            payload: res.subscription_id,
        });

        yield put({
            type: SPINNER,
        });

        yield put({
            type: BUY_PLAN.SUCCESS,
            payload: res,
        });
    } catch (error) {
        yield put({
            type: BUY_PLAN.FAILURE,
            payload: {
                type: 'payment',
                show: true,
            },
        });
        yield put({
            type: MODAL,
        });
    }
}

function* buyFlow() {
    yield takeEvery(BUY_PLAN.REQUEST, buy);
}

export default [buyFlow];