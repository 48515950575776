import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import styles from "./Steps.style";
import { addStyle } from "../../helper/index";

const Steps = ({ t, steps }) => {
  const items = [];

  for (const item in steps) {
    items.push(item);
  }

  let initStyle = styles.steps__item;

  return (
    <ul className={styles.steps__list}>
      {items.map((i) => {
        return (
            <li
                key={i}
                className={addStyle(
                    initStyle,
                    styles.steps__item_active,
                    steps[i].active
                )}
            >
              <span>{t(steps[i].label)}</span>
            </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = ({ main, steps }) => {
  return {
    ...main,
    steps,
  };
};

export default withTranslation()(connect(mapStateToProps)(Steps));
