// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Info-style__info__container--3-kY0{width:100%;margin:0 auto 10px;vertical-align:middle;border-radius:4px}.Info-style__info__container_err--gpX1b{background:#ffeded;display:block}.Info-style__info__container_info--2mMMt{background-color:rgba(0,191,165,0.09);padding:20px 30px 10px;text-align:center}.Info-style__info__container_hidden--3G943{display:none}.Info-style__info_note--2J7L8{width:100%;min-height:62px;padding:10px 50px;font-weight:500;font-size:14px;line-height:18px;color:#00A28C;background-color:rgba(0,191,165,0.1);border:1px solid #00BFA5;box-sizing:border-box;border-radius:3px;text-align:center;letter-spacing:0.313846px}@media screen and (max-width: 767px){.Info-style__info_note--2J7L8{font-size:12px;line-height:14px;padding:10px 30px;min-height:35px}}.Info-style__info__text--UxMg1{font-weight:300;font-size:14px;line-height:19px;text-align:center;color:#f3364e;padding:15px 0;margin:0;box-sizing:border-box}.Info-style__info__text_info--3dCRV{color:#00bfa5}\n", ""]);
// Exports
exports.locals = {
	"info__container": "Info-style__info__container--3-kY0",
	"info__container_err": "Info-style__info__container_err--gpX1b",
	"info__container_info": "Info-style__info__container_info--2mMMt",
	"info__container_hidden": "Info-style__info__container_hidden--3G943",
	"info_note": "Info-style__info_note--2J7L8",
	"info__text": "Info-style__info__text--UxMg1",
	"info__text_info": "Info-style__info__text_info--3dCRV"
};
module.exports = exports;
