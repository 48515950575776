import React, { Fragment } from 'react';
import style from './Checkout.style';
import { connect } from 'react-redux';
import BackBtn from './BackBtn';
import { useTranslation } from 'react-i18next';

import {
    selectPlanActions,
    setMenuAction,
    setShippingAction,
    resetCouponAction
} from '../../redux/actions/main';
import { setStepStateAction } from '../../redux/actions/steps';
import { PLANS } from '../../helper/common';
import { withRouter } from 'react-router';

const Choice = (props) => {
    const {
        plan,
        noTrial,
        isCouponApplied,
        selectPlanActions,
        setMenuAction,
        history: { push },
        setStepStateAction,
        discountedPrice,
        resetCouponAction
    } = props;

    const currentPlan = PLANS[plan];
    const { t } = useTranslation();


    const onBackBtnClick = () => {
        selectPlanActions('default');
        setMenuAction(true);
        noTrial ? push('/nt') : push('/');
        setStepStateAction({ step: '3', active: false });
        setStepStateAction({ step: '2', active: true });
        isCouponApplied && resetCouponAction(false);
    };

    const planNameLocalized = t(currentPlan.name);
    const planPriceLocalized = t(currentPlan.price);
    const planBilledLocalized = t(currentPlan.billedAt);
    const planDescrLocalized = t(currentPlan.descr);


    return (
        <Fragment>
            <h2 className={style.checkout__title}>{t('yourChoice')}</h2>
            <div className={style.checkout__planInfo}>
                <button
                    className={style.checkout__back}
                    aria-label="back"
                    onClick={onBackBtnClick}
                >
                    <BackBtn />
                </button>
                <p className={style.checkout__subTitle}>{planNameLocalized}</p>
                <div className={style.checkout__priceInfo}>
                    {isCouponApplied && (
                        <div className={style.checkout__discounts}>
                            <p className={style.checkout__oldTracker}>{planPriceLocalized}</p>
                            <p className={style.checkout__discountedPrice}>
                ${discountedPrice}
                            </p>
                        </div>
                    )}

                    <p className={style.checkout__descr}>
                        {isCouponApplied ? planBilledLocalized : planDescrLocalized}
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main,
    };
};

const mapDispatchToProps = {
    selectPlanActions,
    setMenuAction,
    setStepStateAction,
    setShippingAction,
    resetCouponAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Choice));
