import React, {useEffect, useState} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { setConsentAction } from 'actions/main';
import { getCountryDataAction } from 'actions/seo';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Payment from './pages/Payment/Payment';
import Spinner from './components/UI/Loader/Loader';
import Summary from './pages/Summary/Summary';
import Success from './pages/Success/Success';
import NotFound from './pages/NotFound/NotFound';
import Alert from './components/UI/Alert/Alert';


const currentAPI = process.env.API_URL_ONE;


const Router = ({ getCountryDataAction }) => {
    const [alert, setAlert] = useState(false);


    useEffect(() => {
        if (currentAPI.includes('stage')) {
            setAlert(true);
        }
    }, []);

    useEffect(() => {
        getCountryDataAction();
    }, []);


    return (
        <BrowserRouter>
            <Header />
            {alert && <Alert/>}
            <Spinner />
            <Switch>
                <Route path="/" exact component={Payment}/>
                <Route path="/nt" exact component={Payment} />
                <Route path="/summary" exact component={Summary} />
                <Route path="/success" exact component={Success} />
                <Route path="*" exact component={NotFound} />
            </Switch>
            <Footer />
        </BrowserRouter>
    );
};


const mapDispatchToProps = {
    setConsentAction,
    getCountryDataAction,
};


export default connect(null, mapDispatchToProps)(Router);