import React from 'react';
import style from './Coupon.style';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { onChange } from 'helper';
import { setCouponAction, applyCouponAction } from 'actions/main';
import classNames from 'classnames/bind';

const Coupon = ({
    t,
    setCouponAction,
    applyCouponAction,
    isCouponApplied,
    coupon,
}) => {
    const styleCoupon = isCouponApplied
        ? classNames(style.coupon__input, style.coupon__input_applied)
        : style.coupon__input;

    const isEmpty = !!coupon.trim().length;

    const onClickHandler = () => {
        applyCouponAction();
    };

    return (
        <div className={style.coupon}>
            <input
                disabled={isCouponApplied}
                type="text"
                onChange={onChange(setCouponAction)}
                className={styleCoupon}
                placeholder={t('coupon')}
            />
            <button
                disabled={!isEmpty || isCouponApplied}
                className={style.coupon__btn}
                onClick={onClickHandler}
            >
                {isCouponApplied ? t('applied') : t('apply')}
            </button>
        </div>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main,
    };
};

const mapDisparchToProps = {
    setCouponAction,
    applyCouponAction,
};

export default withTranslation()(connect(mapStateToProps, mapDisparchToProps)(Coupon));
