import {
    createAsyncAction,
    createAction
} from '../common';



export const SELECT_PLAN = 'slectPlan';
export const selectPlanActions = (payload) => {
    return {
        type: SELECT_PLAN,
        payload,
    };
};

export const SET_USER_LANG = 'setUserLang';
export const setUserLangAction = (payload) => {
    return {
        type: SET_USER_LANG,
        payload,
    };
};


export const SET_CAPTCHA_TOKEN = 'setCaptchaToken';
export const setCaptchaTokenAction = (payload) => {
    return {
        type: SET_CAPTCHA_TOKEN,
        payload,
    };
};

export const GET_BT_TOKEN = createAsyncAction('getBtToken');
export const getBtTokenAction = (payload) => {
    return {
        type: GET_BT_TOKEN.REQUEST,
        payload,
    };
};

export const APPLY_COUPON = createAsyncAction('applyCoupon');
export const applyCouponAction = (payload) => {
    return {
        type: APPLY_COUPON.REQUEST,
        payload,
    };
};

export const SET_DISCOUNTED_PRICE = 'setDiscountedPrice';
export const setDiscountedPriceAction = createAction(SET_DISCOUNTED_PRICE);

export const RESET_COUPON = 'resetCoupon';
export const resetCouponAction = createAction(RESET_COUPON);

export const SET_SHIPPING = 'setShipping';
export const setShippingAction = createAction(SET_SHIPPING);

export const SET_COUPON = 'setCoupon';
export const setCouponAction = (payload) => {
    return {
        type: SET_COUPON,
        payload,
    };
};

export const SET_NONCE = 'setNonce';
export const setNonceAction = (payload) => {
    return {
        type: SET_NONCE,
        payload,
    };
};

export const SET_MENU = 'setMenu';
export const setMenuAction = (payload) => {
    return {
        type: SET_MENU,
        payload,
    };
};

export const SET_NO_TRIAL = 'setNoTrial';
export const setNoTrialAction = (payload) => {
    return {
        type: SET_NO_TRIAL,
        payload,
    };
};

export const SET_CONSENT = 'setConsent';
export const setConsentAction = (payload) => {
    return {
        type: SET_CONSENT,
        payload,
    };
};