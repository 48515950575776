// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Payment-style__payment--2hU9O{display:flex;flex-direction:column}@media screen and (min-width: 1200px){.Payment-style__payment--2hU9O{flex-direction:row !important;justify-content:space-between;flex-wrap:nowrap !important}}\n", ""]);
// Exports
exports.locals = {
	"payment": "Payment-style__payment--2hU9O"
};
module.exports = exports;
