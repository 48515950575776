import React from 'react';
import {withTranslation} from 'react-i18next';
import style from './Footer.style.scss';
import classNames from 'classnames/bind';
import {FB, Insta, Twitter, Linkedin} from './icons';
import Language from '../UI/Language/Language';
import google from 'assets/google.png';
import apple from 'assets/appstore.png';


const renderLink = ({name, url}) => {
    return (
        <li key={name} className={style.Footer__item}>
            <a href={url} className={style.Footer__link}>
                {name}
            </a>
        </li>
    );
};

const renderLinkList = ({title, route}) => (
    <ul className={style.Footer__list} key={title}>
        <li>
            <h3 className={style.Footer__title}>{title}</h3>
        </li>
        {
            route.map(renderLink)
        }
    </ul>
);

const Footer = ({t}) => {
    const LINK = [
        {
            title: t('navigation.about'),
            route: [
                {url: 'https://geozilla.com/about/', name: t('navigation.about')},
                {url: 'https://geozilla.com/about/', name: t('navigation.team')},
                {url: 'https://geozilla.com/blog/', name: t('navigation.blog')},
                {url: 'https://geozilla.com/contact/', name: t('navigation.contactUs')},
            ]
        },
        {
            title: t('navigation.offers'),
            route: [
                {url: 'https://gps-tracker.geozilla.com', name: t('navigation.geoZillaTracker')},
                {url: 'https://geozilla.com/guideline/', name: t('navigation.activateTracker')},
                {url: 'https://premium.geozilla.com/?yearly', name: t('navigation.premiumPlan')},
            ]
        },
        {
            title: t('navigation.technology'),
            route: [
                {url: 'https://findmy.geozilla.com/', name: t('navigation.aboutGZ')},
                {url: 'https://geozilla.com/driving/', name: t('navigation.driverProtection')},
            ]
        },
        {
            title: t('navigation.help'),
            route: [
                {url: 'https://geozillahelp.zendesk.com', name: t('navigation.help')},
                {url: 'https://geozilla.com/privacy-policy/', name: t('navigation.privacy')},
                {url: 'https://geozilla.com/terms-of-use/', name: t('navigation.terms')},
                {url: 'https://geozilla.com/contact/', name: t('navigation.contactUs')},
                {url: 'https://jobs.dou.ua/companies/geozilla/vacancies', name: t('navigation.careers')},
            ]
        },
        {
            title: t('navigation.social'),
            route: [
                {url: 'https://www.instagram.com/geozilla_family', name: 'Instagram'},
                {url: 'https://www.facebook.com/GeoZillaApp', name: 'Facebook'},
                {url: 'https://twitter.com/GeoZillaApp', name: 'Twitter'},
                {url: 'https://www.linkedin.com/company/geozilla', name: 'LinkedIn'},
            ]
        },
    ];

    return (
        <div className={style.Footer__wr}>
            <div className='container'>
                <div className={style.Footer__titleBlock}>
                    <p className={style.Footer__head}>{t('downloadGeoZilla')}</p>
                    <a href='https://go.onelink.me/app/geozillaapp' className={style.Footer__storeBtn}>
                        <img src={apple} width='130' height='90' alt=''/>
                    </a>
                    <a href='https://go.onelink.me/app/geozillaandroid'
                       className={style.Footer__storeBtn}>
                        <img src={google} alt='Download GeoZilla Google Play Store badge'/>
                    </a>
                </div>
                <nav className={style.Footer__nav}>
                    {LINK.map(renderLinkList)}
                </nav>
                <div className={style.Footer__soc}>
                    <p>{t('copy')}</p>
                    <div className={style.Footer__termsLinks}>
                        <a href={'https://geozilla.com/privacy-policy/'} className={style.Footer__terms}>{t('navigation.privacy')}</a>
                        <a href={'https://geozilla.com/terms-of-use/'} className={style.Footer__terms}>{t('navigation.terms')}</a>
                    </div>
                    <Language/>
                    <div className={style.Footer__icons}>
                        <a href='https://www.facebook.com/GeoZillaApp' className={style.Footer__ico}>
                            <FB/>
                        </a>
                        <a href='https://twitter.com/GeoZillaApp' className={style.Footer__ico}>
                            <Twitter/>
                        </a>
                        <a href='https://www.linkedin.com/company/geozilla' className={style.Footer__ico}>
                            <Linkedin/>
                        </a>
                        <a href='https://www.instagram.com/geozilla_family' className={style.Footer__ico}>
                            <Insta/>
                        </a>
                    </div>
                </div>
            </div>
        </div>);
}


export default withTranslation()(Footer);
