import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './Success.style';
import circle from 'assets/circle.svg';
import phone from 'assets/phone.png';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import google from 'assets/google.png';
import apple from 'assets/appstore.png';
import { withRouter } from 'react-router';
import { setMetrics } from '../../analytics';
import QRCode from 'qrcode.react';

const LINK = process.env.PREMIUM_LINK;


const Success = (props) => {
    const { plan, newUser, subscriptionID, coupon, discountedPrice } = props;

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        if (plan && subscriptionID) {
            setMetrics(plan, subscriptionID, discountedPrice, coupon);
        }
    }, [plan, subscriptionID, discountedPrice, coupon]);

    const newUserFlow = (
        <ol className={style.success__list}>
            <li className={style.success__item}>{t('confirmEmail')}</li>
            <li className={style.success__item}>
                {t('setPassword')}
            </li>
            <li className={style.success__item}>
                {t('downloadAndLogin')}
            </li>
        </ol>
    );


    const oldUserApp = (
        <p className={style.success__text}>
            {t('loginOnYourPhone')}
        </p>
    );

    const buttonsBlock = (
        <div className={style.success__buttons}>
            <a href={LINK}>
                <img
                    src={apple}
                    alt="Download from AppStore"
                    className={style.success__btn}
                />
            </a>
            <a href={LINK}>
                <img
                    src={google}
                    className={style.success__btn}
                    alt="Download from GooglePlay"
                />
            </a>
        </div>
    );


    const qrCodeBlock = (
        <>
            <p className={style.success__qr_title}>{t('scanQR')}</p>
            <div className={style.success__qr_container}>
                <p className={style.success__qr__option}>{t('loginToGZ')}</p>

                <div className={style.success__qr}>
                    <QRCode value={LINK} size={200}/>
                </div>
                <p className={style.success__qr__option}>{t('openLink')}</p>
                <a href={LINK}>{LINK}</a>
            </div>
        </>
    );

    return (
        <div className="wrapper">
            <div className={classNames('container', style.success)}>
                <img src={circle} alt="Success" className={style.success__circle} />
                <h1 className={style.success__title}>{t('premiumReady')}</h1>

                <div className={style.success__content}>
                    {newUser ? newUserFlow : oldUserApp}
                </div>

                {isMobile ? buttonsBlock : qrCodeBlock}

                <img className={style.success__phone} src={phone} alt="GeoZilla App" />
            </div>
        </div>
    );
};

const mapStateToProps = ({ main, user, checkout }) => {
    return {
        ...main,
        ...user,
        ...checkout,
    };
};

export default connect(mapStateToProps)(withRouter(Success));
