import React, { Fragment } from "react";
import { withTranslation } from 'react-i18next';
import style from './ProductInfo.style';
import jake from 'assets/jake.png';


const ProductInfo = ({ t }) => {

  return (
    <Fragment>
      <section className={style.product}>
        <h1 className={style.product__title}>
          {t('title')}
        </h1>
        <p className={style.product__promoText}>{t('promoText')}</p>
        <h2 className={style.product__subTitle}>{t('promoTitle')}</h2>
        <ul className={style.product__list}>
          <li className={style.product__item}>
            {t('features.1')}
          </li>
          <li className={style.product__item}>
            {t('features.2')}
          </li>
          <li className={style.product__item}>
            {t('features.3')}
          </li>
        </ul>
        <div className={style.product__review}>
          <img src={jake} alt="GeoZilla User" className={style.product__img} />
          <p className={style.product__name}>
            <span>{t('review.author')}</span>
            {t('review.city')}
          </p>
          <p className={style.product__content}>{t('review.text')}</p>
        </div>
      </section>
    </Fragment>
  );
};


export default withTranslation()(ProductInfo);
