import React, { Fragment, useEffect, Suspense } from 'react';
import style from './Checkout.style';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setStepStateAction } from 'actions/steps';
import { connect } from 'react-redux';
import Info from '../UI/Info/Info';
const CardForm = React.lazy(() => import('../../components/CardForm/CardForm'));
import Coupon from '../../components/Coupon/Coupon';
import Choice from './Choice';
import Login from '../../components/Login/Login';
import ChoosePlan from './ChoosePlan';
import { setGeneralErrorAction } from 'actions/error';
import { getBtTokenAction } from 'actions/main';
import { PLANS } from '../../helper/common';
import InfoNote from '../UI/Info/InfoNote';

const Checkout = (props) => {
    const {
        plan,
        captchaTokenBrainTree,
        getBtTokenAction,
        isMenuShown,
        history: { push },
    } = props;

    const { t } = useTranslation();

    const currentPlan = plan ? PLANS[plan] : PLANS['default'];


    useEffect(() => {
        captchaTokenBrainTree && getBtTokenAction();
    }, [captchaTokenBrainTree]);



    const paymentScreen = (
        <Suspense fallback={<div></div>}>
            <Login />
            <Info />
            <InfoNote text={t('paymentDisclaimer')}/>
            <CardForm currentPlan={currentPlan} push={push} />
            <Coupon />
            <p className={style.checkout__googleTerms}>
                This site is protected by reCAPTCHA and the Google
                &nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a> and
                &nbsp;<a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
        </Suspense>
    );

    return (
        <Fragment>
            {isMenuShown && <ChoosePlan />}

            {plan !== 'default' && !isMenuShown && (
                <div className={style.checkout__wrapper}>
                    <Choice plan={currentPlan} />
                    <div className={style.checkout}>{paymentScreen}</div>
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = ({ main, form, card, error, user, checkout }) => {
    return {
        ...main,
        form,
        ...card,
        error,
        ...user,
        ...checkout,
    };
};

const mapDispatchToProps = {
    getBtTokenAction,
    setGeneralErrorAction,
    setStepStateAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Checkout));
