import React from 'react';
import style from './Checkout.style';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import greenBtn from 'assets/buy-btn.svg';
import orangeBtn from 'assets/buy-btn-main.svg';
import { selectPlanActions, setMenuAction } from '../../redux/actions/main';
import { setStepStateAction } from '../../redux/actions/steps';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { scrollToTop } from '../../helper/index';

const ChoosePlan = (props) => {

    const {
        selectPlanActions,
        setMenuAction,
        noTrial,
        history: { push },
        setStepStateAction,
    } = props;
    
    const planToBuy = noTrial ? 'quarterly' : 'yearly';
    const priceForMainOffer = noTrial ? '$7.50 ' : '$3.99 ';


    const onClickHandler = (plan) => {
        selectPlanActions(plan);
        setMenuAction(false);
        noTrial ? push('/nt?' + plan) : push('/?' + plan);
        scrollToTop();
        setStepStateAction({ step: 2, active: false });
        setStepStateAction({ step: 3, active: true });
    };

    const { t } = useTranslation();


    return (
        <div className={style.choose}>
            <p className={style.choose__heading}>{t('choosePlan')}</p>
            <button
                className={classNames(style.choose__card, style.choose__card_main)}
                data-content={t('exclusiveDeal')}
                aria-label={noTrial ? 'Buy Quarterly Subscription' : 'Buy Yearly Subscription'}
                onClick={() => onClickHandler(planToBuy)}
            >
                <p
                    className={classNames(style.choose__title, style.choose__title_main)}
                >{noTrial ? t('quarterlyPlan.name') : t('yearlyPlanT.name')}
                </p>
                <p
                    className={classNames(
                        style.choose__price,
                        style.choose__price_main
                    )}
                > {priceForMainOffer + '' + t('monthlyPlan.billingPeriod')}
                </p>
                <p
                    className={classNames(style.choose__save, style.choose__save_main)}
                >{noTrial ? t('save25') : t('save60')}
                </p>

                <p
                    className={classNames(style.choose__terms, style.choose__terms_main)}
                >{noTrial ? t('quarterlyPlan.billingTerms') : t('yearlyPlanT.billingTerms')}
          
                </p>
                <div className={classNames(style.choose__btn, style.choose__btn_main)}>
                    <img src={orangeBtn} alt="Buy button" />
                </div>
            </button>

            <button
                className={style.choose__card}
                aria-label={'Buy Monthly Subscription'}
                onClick={() => onClickHandler('monthly')}
            >
                <p className={style.choose__title}>{t('monthlyPlan.name')}</p>
                <p className={style.choose__price}>{t('monthlyPlan.price') + ' ' + t('monthlyPlan.billingPeriod')}</p>
                <p className={style.choose__terms}>{t('monthlyPlan.billingTerms')}</p>
                <div className={style.choose__btn}>
                    <img src={greenBtn} alt="Buy button" />
                </div>
            </button>
        </div>
    );
};

const mapStateToProps = ({ main }) => {
    return {
        ...main
    };
};

const mapDispatchToProps = {
    selectPlanActions,
    setMenuAction,
    setStepStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChoosePlan));
