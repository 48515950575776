import React from "react";
import style from "./Error.style";
import { useTranslation } from 'react-i18next';
import Button from "../Button/Button";
import { connect } from "react-redux";
import { showModalAction } from "actions/popup";

const Error = ({ showModalAction }) => {

    const { t } = useTranslation();

  return (
    <div className={style.error}>
      <p className={style.error__title}>{t('errors.somethingWentWrong')}</p>
      <p className={style.error__body}>{t('errors.errorPayment')}</p>
      <Button type="small" onClick={showModalAction}>
        Ok
      </Button>
    </div>
  );
};

const mapStateToProps = ({ error }) => {
  return {
    ...error,
  };
};

const mapDispatchToProps = {
  showModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
