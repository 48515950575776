import React from "react";
import style from "./Card.style";

const Card = (props) => {
  const cls = [style.card, style[props.type]];

  return <div className={cls.join(" ")}>{props.children}</div>;
};

export default Card;
