import { put, call, takeEvery } from 'redux-saga/effects';
import { GET_COUNTRY_DATA } from '../actions/seo';
import API from 'api';
const DEFAULT_COUNTRY = 'US';


function* getCountryData() {
    try {
        const res = yield call(API.getCountryInfo);
        yield put({ type: GET_COUNTRY_DATA.SUCCESS, payload: res.country_code });
    } catch (error) {
        yield put({ type: GET_COUNTRY_DATA.FAILURE, payload: DEFAULT_COUNTRY });
    }
}

function* getCountryDataFlow() {
    yield takeEvery(GET_COUNTRY_DATA.REQUEST, getCountryData);
}

export default [
    getCountryDataFlow
];
