import { createReducer } from '../common';
import {
    BUY_PLAN,
    SET_SUBSC_ID,
} from 'actions/checkout';

const initState = {
    done: false,
    orderId: '',
    subscriptionID: '',
};

const done = (state, { payload }) => {
    return {
        ...state,
        done: !!payload,
    };
};

const setSubscriptionId = (state, { payload }) => {
    return {
        ...state,
        subscriptionID: payload,
    };
};

const handlers = {
    [BUY_PLAN.SUCCESS]: done,
    [SET_SUBSC_ID]: setSubscriptionId,
};

export default createReducer(initState, handlers);
