// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button-style__button--1kUx2{width:296px;height:48px;background-color:#00bfa5;border-radius:100px;border:none;color:#fff;font-weight:600;font-size:16px;line-height:19px;text-transform:uppercase;outline:none}@media screen and (max-width: 374px){.Button-style__button--1kUx2{width:230px}}@media screen and (min-width: 768px){.Button-style__button--1kUx2{font-size:18px;line-height:21px}}.Button-style__button--1kUx2:hover{background-color:#22caa9}.Button-style__button--1kUx2:active{background-color:#00a68f}.Button-style__button--1kUx2:focus{outline:none}.Button-style__button--1kUx2:disabled{background-color:#9c9c9c}.Button-style__checkout--aZtQF{width:100%;text-transform:none;margin:24px auto}@media screen and (min-width: 768px){.Button-style__checkout--aZtQF{width:399px;margin:0 auto}}.Button-style__cookies--1twe_{width:178px;margin:6px auto;text-transform:none;height:36px;font-size:14px}.Button-style__signin--2C-no{text-transform:none;margin:10px auto}.Button-style__small--1BXdu{width:200px}.Button-style__content--sLM06{margin-top:16px}\n", ""]);
// Exports
exports.locals = {
	"button": "Button-style__button--1kUx2",
	"checkout": "Button-style__checkout--aZtQF",
	"cookies": "Button-style__cookies--1twe_",
	"signin": "Button-style__signin--2C-no",
	"small": "Button-style__small--1BXdu",
	"content": "Button-style__content--sLM06"
};
module.exports = exports;
