import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import ProductInfo from '../../components/ProductInfo/ProductInfo';
import Checkout from '../../components/Checkout/Checkout';
import {getProduct, HELP_SCRIPT} from '../../helper/common';
import {selectPlanActions, setMenuAction, setNoTrialAction, setCaptchaTokenAction} from '../../redux/actions/main';
import {setStepStateAction} from '../../redux/actions/steps';
import classNames from 'classnames/bind';
import style from './Payment.style';
import {verifyGoogleToken, loadConditionalScript} from 'helper';


const Payment = ({
                     plan,
                     selectPlanActions,
                     setStepStateAction,
                     setMenuAction,
                     setNoTrialAction,
                     setCaptchaTokenAction,
                     showSupportWidget,
                     location: {pathname},
                 }) => {

    let currentProduct = getProduct();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const isNoTrial = pathname === '/nt';
        setNoTrialAction(isNoTrial);
    }, []);

    useEffect(() => {
        selectPlanActions(currentProduct.url);
    }, [currentProduct]);

    useEffect(() => {
        setTimeout(() => verifyGoogleToken(setCaptchaTokenAction, 'braintree'), 1000);
    }, []);

    useEffect(() => {
        if (showSupportWidget) {
            loadConditionalScript(null, HELP_SCRIPT);
        }
    }, [showSupportWidget])


    useEffect(() => {
        if (plan !== 'default') {
            setMenuAction(false);
            setStepStateAction({step: 3, active: true});
            setStepStateAction({step: 2, active: false});
        } else {
            setMenuAction(true);
            setStepStateAction({step: 3, active: false});
            setStepStateAction({step: 2, active: true});
        }
    }, [plan]);

    return (
        <div className="wrapper">
            <div className={classNames('container', style.payment)}>
                <Checkout/>
                <ProductInfo/>
            </div>
        </div>
    );
};

const mapStateToProps = ({main, seo}) => {
    return {
        ...main,
        ...seo
    };
};

const mapDispatchToProps = {
    selectPlanActions,
    setStepStateAction,
    setMenuAction,
    setNoTrialAction,
    setCaptchaTokenAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
