/* eslint-disable no-useless-escape */
import { NO_SUPPORT_CODES } from './common';
const {CAPTCHA_KEY} = process.env;

export const setAFCustomerId = userId => {
    if (userId) {
        const CUID = userId.toString();
        return window.AF('pba', 'setCustomerUserId', `'${CUID}'`);
    }

    return null;
};

export const getCookie = name => {
    let matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const toParam = (obj) => {
    const keys = Object.keys(obj);

    const str = keys.reduce((acc, item) => {
        return acc + item + '=' + obj[item] + '&';
    }, '?');

    return str;
};

export const onChange = (fn) => ({
    target: {
        value
    }
}) => fn(value);

export const requestAuth = (data) => {
    const config = {};
    if (typeof data === 'string') {
        Object.assign(config, {
            headers: {
                Authorization: `Bearer ${data}`,
            },
        });
        return config;
    }

    const {
        token,
        ...params
    } = data;
    
    Object.assign(config, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params,
    });
    return config;
};

export const addStyle = (style, styleError, bool) => {
    if (bool) {
        return style + ' ' + styleError;
    }
    return style;
};

export const pushGoogleTag = (data) => {
    window.dataLayer.push(data);
};

export const pushFBEvent = (data) => {
    if (window.fbq) {
        window.fbq('track', 'Purchase', data);
    }
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const verifyGoogleToken = (action, type) => {

    const captchaKey = CAPTCHA_KEY.toString();

    window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute(captchaKey, {
                action: 'homepage'
            })
            .then(token => {
                action({token, type});
            });
    });
};

export const showSupport = code => {
    return NO_SUPPORT_CODES.includes(code);
};

export const loadConditionalScript = (src, scriptBody) => {

    if (src) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = src;
    }

    if (scriptBody) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = scriptBody;
        document.body.appendChild(script);
    }
};
