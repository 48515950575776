import { put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    LOGIN_USER,
    LOGIN_USER_EMAIL,
    REGISTER_USER_EMAIL,
    GET_USER_INFO,
    LOGOUT_USER,
    RESET_PASSWORD,
} from 'actions/user';
import { SPINNER, MODAL } from 'actions/popup';
import API from 'api';


function* getUserInfo({ payload }) {
    try {
        const {
            user: { token },
        } = yield select();
        const res = yield call(API.getUserInfo, token);
        yield put({ type: GET_USER_INFO.SUCCESS, payload: res });
    } catch (error) {
        yield put({
            type: GET_USER_INFO.FAILURE,
            payload: { type: '', show: true},
        });
    }
}

function* getUserInfoFlow() {
    yield takeEvery(GET_USER_INFO.REQUEST, getUserInfo);
}

function* loginSoc({ payload }) {
    yield put({ type: SPINNER });
    try {
        const res = yield call(API.loginSoc, payload);
        yield put({ type: LOGIN_USER.SUCCESS, payload: res });
    } catch (error) {
        yield put({
            type: LOGIN_USER.FAILURE,
            payload: { type: '', show: true },
        });
    }
    yield put({ type: SPINNER });
}

function* loginSocFlow() {
    yield takeEvery(LOGIN_USER.REQUEST, loginSoc);
}

function* registerEmail({ payload }) {
    yield put({ type: SPINNER });

    try {
        const {
            main: {captchaTokenSignup},
            user: { driver: registration_source, email },
        } = yield select();
        const res = yield call(API.registerForEmail, {
            captchaTokenSignup,
            params: {
                registration_source,
                email,
                name: email,
            }
        });
        yield put({ type: REGISTER_USER_EMAIL.SUCCESS, payload: res });
    } catch (error) {
        yield put({
            type: REGISTER_USER_EMAIL.FAILURE,
            payload: { type: '', show: false},
        });
    }
    yield put({ type: SPINNER });
}

function* registerEmailFlow() {
    yield takeLatest(REGISTER_USER_EMAIL.REQUEST, registerEmail);
}

function* loginEmail({ payload }) {
    yield put({ type: SPINNER });
    try {
        const {
            user: { password, email, driver: registration_source },
        } = yield select();
        const res = yield call(API.loginEmail, {
            email,
            password,
            registration_source,
        });
        yield put({ type: LOGIN_USER_EMAIL.SUCCESS, payload: res });
        yield put({ type: MODAL });
    } catch (error) {
        yield put({
            type: LOGIN_USER.FAILURE,
            payload: { type: '', show: true },
        });
    }
    yield put({ type: SPINNER });
}

function* loginEmailFlow() {
    yield takeEvery(LOGIN_USER_EMAIL.REQUEST, loginEmail);
}
function* logoutUser({ payload }) {
    yield put({ type: SPINNER });
    try {
        const {
            user: { token },
        } = yield select();
        const res = yield call(API.logoutUser, token);
        yield put({ type: LOGOUT_USER.SUCCESS, payload: res });
    } catch (error) {
        yield put({
            type: LOGOUT_USER.FAILURE,
            payload: { type: 'login', show: true},
        });
    }
    yield put({ type: SPINNER });
}

function* logoutUserFlow() {
    yield takeEvery(LOGOUT_USER.REQUEST, logoutUser);
}

function* resetPassword({ payload }) {
    yield put({ type: SPINNER });
    try {
        const {
            user: { email },
        } = yield select();
        const res = yield call(API.resetPassword, { email });
        yield put({ type: RESET_PASSWORD.SUCCESS, payload: res });
    } catch (error) {
        yield put({
            type: RESET_PASSWORD.FAILURE,
            payload: {
                type: 'reset',
                show: true,
            },
        });
    }
    yield put({ type: SPINNER });
}

function* resetPasswordFlow() {
    yield takeEvery(RESET_PASSWORD.REQUEST, resetPassword);
}

export default [
    loginSocFlow,
    loginEmailFlow,
    registerEmailFlow,
    getUserInfoFlow,
    logoutUserFlow,
    resetPasswordFlow,
];
