import {
    pushFBEvent,
    pushGoogleTag
} from 'helper';

const PLANS = {
    yearly: {
        name: 'gz_1_year_47_99_3d_trial',
        price: 0,
    },

    monthly: {
        name: 'Monthly_Premium_Subscription_A',
        price: 9.99,
    },
    quarterly: {
        name: 'gz_3_month_29_99_no_trial',
        price: 29.99,
    },
};

export const gaUserId = () => {
    let id = '';

    if (document.cookie.match(/_ga=(.+?);/)) {
        id = document.cookie.match(/_ga=(.+?);/)[1];

        return id.split('.')
            .slice(-2)
            .join('.');
    }

    return null;
};

export const setMetrics = (plan, id, discountedPrice, coupon) => {

    const total = discountedPrice || PLANS[plan].price;

    const googleAnalyticsData = {
        event: 'autoEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Purchase',
        orderValue: total,
        orderId: id,
        ecommerce: {
            purchase: {
                actionField: {
                    id: id,
                    affiliation: 'Internet Shop',
                    revenue: total,
                    tax: 0,
                    shipping: 0
                },
                products: [
                    {
                        name: PLANS[plan].name,
                        price: PLANS[plan].price,
                        quantity: 1
                    }
                ]
            }
        }
    };

    const googleAnalytics3Data = {
        event: 'purchase',
        ecommerce: {
            purchase: {
                transaction_id: id,
                affiliation: 'Online Store',
                value: total,
                tax: 0,
                shipping: 0,
                currency: 'USD',
                coupon: coupon,
                items: [{
                    item_name: PLANS[plan].name,
                    item_id: PLANS[plan].name,
                    item_price: PLANS[plan].price,
                    quantity: 1,
                    item_coupon: coupon
                }]
            }
        }
    };

    const successShown = {
        event: 'autoEvent',
        eventCategory: 'Subscription2_0 Order Page',
        eventAction: 'Success Screen Shown',
    };

    pushGoogleTag(successShown);
    pushGoogleTag(googleAnalyticsData);
    pushGoogleTag(googleAnalytics3Data);

    const fbData = {
        value: PLANS[plan].price,
        currency: 'USD',
    };

    pushFBEvent(fbData);
};