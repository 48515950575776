import React, {useEffect} from 'react';
import style from './Info.style';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import classNames from 'classnames/bind';
import {setGeneralErrorAction} from 'actions/error';

const Info = (props) => {
    const {error, setGeneralErrorAction} = props;
    const {t} = useTranslation();

    const cls = error.show
        ? classNames(style.info__container, style.info__container_err)
        : style.info__container_hidden;

    useEffect(() => {
        if (error.show) {
            setTimeout(
                () => setGeneralErrorAction({show: false, type: ''}),
                5000
            );
        }

    }, [error.show]);
    //
    let errorText;

    switch (error.type) {
        case 'coupon':
             errorText = t('errors.errorCoupon');
             break
        case 'reset':
             errorText = t('errors.errorReset');
            break
        case 'userExists':
             errorText = t('errors.errorExist');
            break
        case 'register':
             errorText = t('errors.errorNoAccount');
            break
        case 'email':
             errorText = t('errors.errorEmail');
            break
        case 'braintree':
             errorText = t('errors.errorUnknown');
            break
        case 'invalidEmail':
             errorText = t('errors.errorEmail');
            break
        case 'noMethod':
             errorText = t('errors.errorNoMethod');
            break

        default:
            errorText = t('errors.errorUnknown');
    }



    return (
        <div className={cls}>
            <p className={style.info__text}>{errorText}</p>
        </div>
    );
};

const mapStateToProps = ({error}) => {
    return {
        error,
    };
};

const mapDispatchToProps = {
    setGeneralErrorAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
