import { createReducer } from '../common';
import {
    LOGIN_USER,
    REGISTER_USER_EMAIL,
    LOGOUT_USER,
    RESET_PASSWORD,
} from 'actions/user';
import { GET_BT_TOKEN, APPLY_COUPON } from 'actions/main';
import { SET_ERROR } from 'actions/error';
import { BUY_PLAN } from 'actions/checkout';

const initState = {
    type: '',
    show: false
};

const showError = (state, { payload }) => {
    return {
        ...state,
        ...payload,
    };
};

const handlers = {
    [LOGIN_USER.FAILURE]: showError,
    [REGISTER_USER_EMAIL.FAILURE]: showError,
    [LOGIN_USER.FAILURE]: showError,
    [LOGOUT_USER.FAILURE]: showError,
    [GET_BT_TOKEN.FAILURE]: showError,
    [RESET_PASSWORD.FAILURE]: showError,
    [SET_ERROR]: showError,
    [BUY_PLAN.FAILURE]: showError,
    [APPLY_COUPON.FAILURE]: showError,
};

export default createReducer(initState, handlers);
