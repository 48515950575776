// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes Loader-style__spinLoader--Nod_D{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.Loader-style__loader--2UYE-{width:100vw;height:100vh;background-color:#ffffffd6;border-radius:4px;position:fixed;top:0;left:0;z-index:55;display:flex;justify-content:center;align-content:center;align-items:center}.Loader-style__loader__spinner--2gffb{height:100px;width:100px;border-radius:50%;background-color:transparent;border:3px solid #23cba7;border-right-color:transparent;animation:Loader-style__spinLoader--Nod_D 2s linear infinite}\n", ""]);
// Exports
exports.locals = {
	"loader": "Loader-style__loader--2UYE-",
	"loader__spinner": "Loader-style__loader__spinner--2gffb",
	"spinLoader": "Loader-style__spinLoader--Nod_D"
};
module.exports = exports;
