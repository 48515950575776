import { put, call, select, takeEvery } from 'redux-saga/effects';
import { GET_BT_TOKEN, APPLY_COUPON, SET_DISCOUNTED_PRICE } from '../actions/main';
import { SPINNER } from 'actions/popup';
import API from 'api';

function* getBtToken() {
    const {
        main: {
            captchaTokenBrainTree
        }
    } = yield select();

    try {
        const res = yield call(API.getBtToken, {captchaTokenBrainTree});
        yield put({
            type: GET_BT_TOKEN.SUCCESS,
            payload: res
        });
    } catch (error) {
        console.log(error);
    }
}

function* getBtTokenFlow() {
    yield takeEvery(GET_BT_TOKEN.REQUEST, getBtToken);
}

function* applyCoupon() {
    const {
        main: {
            coupon,
            plan
        },
    } = yield select();

    yield put({
        type: SPINNER
    });

    let slug = '1-year-plan-apr';

    switch (plan) {
    case 'yearly':
        slug = '1-year-plan-apr';
        break;
    case 'monthly':
        slug = 'monthly-premium-subscription-a';
        break;
    case 'quarterly':
        slug = 'gz-3-month-no-trial-plan';
        break;
    default:
        slug = 'yearly';
    }

    try {

        const res = yield call(API.applyCoupon, {
            coupon,
            plan: slug
        });


        yield put({
            type: APPLY_COUPON.SUCCESS,
            payload: true
        });
        yield put({
            type: SET_DISCOUNTED_PRICE,
            payload: {
                discountAmount: res.discount_total,
                discountedPrice: res.price_total,
            },
        });
    } catch (error) {
        yield put({
            type: APPLY_COUPON.FAILURE,
            payload: {
                type: 'coupon',
                show: true,
            },
        });
    }

    yield put({
        type: SPINNER
    });
}

function* applyCouponFlow() {
    yield takeEvery(APPLY_COUPON.REQUEST, applyCoupon);
}

export default [getBtTokenFlow, applyCouponFlow];