import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ru from '../public/locales/ru/translation.json';
import en from '../public/locales/en/translation.json';
import pt from '../public/locales/pt/translation.json';
import es from '../public/locales/es/translation.json';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: navigator.language || navigator.userLanguage,
    resources: {
        en: {
            translation: en
        },
        ru: {
            translation: ru
        },
        pt: {
            translation: pt
        },
        es: {
            translation: es
        }
    },
    interpolation: {
        escapeValue: false
    },
    wait: true
});
export default i18n;
