// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error-style__error--3HUsw{width:260px;display:flex;flex-direction:column;align-items:center;justify-content:space-between;padding:15px;box-sizing:border-box}.Error-style__error__title--2dWok{font-weight:700;font-size:34px;line-height:46px;color:#000}.Error-style__error__body--3pRg0{font-weight:300;font-size:14px;line-height:20px;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"error": "Error-style__error--3HUsw",
	"error__title": "Error-style__error__title--2dWok",
	"error__body": "Error-style__error__body--3pRg0"
};
module.exports = exports;
