import React from 'react';

export const FB = () => (
    <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 8.25V5.25C7.5 4.422 8.172 3.75 9 3.75H10.5V0H7.5C5.0145 0 3 2.0145 3 4.5V8.25H0V12H3V24H7.5V12H10.5L12 8.25H7.5Z" fill="#4D4D4D"/>
    </svg>
);

export const Insta = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 0H7.5C3.3585 0 0 3.3585 0 7.5V16.5C0 20.6415 3.3585 24 7.5 24H16.5C20.6415 24 24 20.6415 24 16.5V7.5C24 3.3585 20.6415 0 16.5 0ZM21.75 16.5C21.75 19.395 19.395 21.75 16.5 21.75H7.5C4.605 21.75 2.25 19.395 2.25 16.5V7.5C2.25 4.605 4.605 2.25 7.5 2.25H16.5C19.395 2.25 21.75 4.605 21.75 7.5V16.5Z" fill="#4D4D4D"/>
        <path d="M12 6C8.6865 6 6 8.6865 6 12C6 15.3135 8.6865 18 12 18C15.3135 18 18 15.3135 18 12C18 8.6865 15.3135 6 12 6ZM12 15.75C9.933 15.75 8.25 14.067 8.25 12C8.25 9.9315 9.933 8.25 12 8.25C14.067 8.25 15.75 9.9315 15.75 12C15.75 14.067 14.067 15.75 12 15.75Z" fill="#4D4D4D"/>
        <path d="M18.45 6.34948C18.8916 6.34948 19.2495 5.99154 19.2495 5.54999C19.2495 5.10844 18.8916 4.75049 18.45 4.75049C18.0085 4.75049 17.6505 5.10844 17.6505 5.54999C17.6505 5.99154 18.0085 6.34948 18.45 6.34948Z" fill="#4D4D4D"/>
    </svg>

);

export const Linkedin = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.367 7.5H0V24H5.367V7.5Z" fill="#4D4D4D"/>
        <path d="M19.986 7.6935C19.929 7.6755 19.875 7.656 19.815 7.6395C19.743 7.623 19.671 7.6095 19.5975 7.5975C19.3125 7.5405 19.0005 7.5 18.6345 7.5C15.5055 7.5 13.521 9.7755 12.867 10.6545V7.5H7.5V24H12.867V15C12.867 15 16.923 9.351 18.6345 13.5C18.6345 17.2035 18.6345 24 18.6345 24H24V12.8655C24 10.3725 22.2915 8.295 19.986 7.6935Z" fill="#4D4D4D"/>
        <path d="M2.625 5.25C4.07475 5.25 5.25 4.07475 5.25 2.625C5.25 1.17525 4.07475 0 2.625 0C1.17525 0 0 1.17525 0 2.625C0 4.07475 1.17525 5.25 2.625 5.25Z" fill="#4D4D4D"/>
    </svg>

);

export const Twitter = () => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 2.5585C23.1075 2.95 22.1565 3.2095 21.165 3.3355C22.185 2.7265 22.9635 1.7695 23.3295 0.616C22.3785 1.183 21.3285 1.5835 20.2095 1.807C19.3065 0.8455 18.0195 0.25 16.6155 0.25C13.8915 0.25 11.6985 2.461 11.6985 5.1715C11.6985 5.5615 11.7315 5.9365 11.8125 6.2935C7.722 6.094 4.1025 4.1335 1.671 1.147C1.2465 1.8835 0.9975 2.7265 0.9975 3.634C0.9975 5.338 1.875 6.8485 3.183 7.723C2.3925 7.708 1.617 7.4785 0.96 7.117C0.96 7.132 0.96 7.1515 0.96 7.171C0.96 9.562 2.6655 11.548 4.902 12.0055C4.5015 12.115 4.065 12.1675 3.612 12.1675C3.297 12.1675 2.979 12.1495 2.6805 12.0835C3.318 14.032 5.127 15.4645 7.278 15.511C5.604 16.8205 3.4785 17.6095 1.1775 17.6095C0.774 17.6095 0.387 17.5915 0 17.542C2.1795 18.9475 4.7625 19.75 7.548 19.75C16.602 19.75 21.552 12.25 21.552 5.749C21.552 5.5315 21.5445 5.3215 21.534 5.113C22.5105 4.42 23.331 3.5545 24 2.5585Z" fill="#4D4D4D"/>
    </svg>

);


