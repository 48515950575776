import React, { Fragment, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import style from './Login.style';
import facebook from 'assets/facebook.svg';
import google from 'assets/google.svg';
import email from 'assets/email.svg';
import useLoadScripts from '../../scripts/ScriptProvider';
import Modal from '../../components/UI/ModalPopup/ModalPopup';
import SigninForm from '../../components/SigninForm/SigninForm';
import { connect } from 'react-redux';
import { addStyle, setAFCustomerId, verifyGoogleToken } from 'helper';
import {
    loginUserSocAction,
    setParamsAction,
    registerUserEmailAction,
    setEmailAction,
    getUserInfoAction,
    logoutUserAction,
} from 'actions/user';
import { showModalAction } from 'actions/popup';
import { setCaptchaTokenAction } from 'actions/main';
import { setGeneralErrorAction } from 'actions/error';
import FB from '../../scripts/FB';
import GGL from '../../scripts/GGL';

const SOC_IMG = {
    facebook,
    google,
    email,
};

const styleEmailAuth = style.login__form_input;
const styleFBAuth =
  style.login__soc_auth__btn + ' ' + style.login__soc_auth__btn_fb;
const styleGGLAuth =
  style.login__soc_auth__btn + ' ' + style.login__soc_auth__btn_ggl;
const err = style.login__err;

const renderAuth = (t, driver, userInfo, logout) => {
    const { name, email } = userInfo;

    return (
        <Fragment>
            <div className={style.login__auth}>
                <p className={style.login__auth_text}>{t('joinedVia') + ' ' + driver}</p>
                <img className={style.login__auth_btn} src={SOC_IMG[driver]} alt="" />
                <p className={style.login__auth_name}>{name || email}</p>
            </div>
            <button className={style.login__auth__logout} onClick={logout}>
                {t('switchAccount')}
            </button>
        </Fragment>
    );
};

const validateEmail = (email) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

const loginFB = (setParamsAction) => () => {
    window.FB.login(
        (res) => {
            if (res.status === 'connected') {
                const params = {
                    access_token: res.authResponse.accessToken,
                    driver: 'facebook',
                };
                return setParamsAction(params);
            }
        },
        { scope: 'email' }
    );
};

const loginGGL = (setParamsAction) => () => {
    window.GoogleAuth.signIn().then((gglRes) => {
        const res = gglRes.getAuthResponse();

        const params = {
            access_token: res.access_token,
            driver: 'google',
        };
        setParamsAction(params);
    });
};

const Login = (props) => {
    const {
        t,
        loginUserSocAction,
        driver,
        logined,
        email,
        setParamsAction,
        access_token,
        registerUserEmailAction,
        setEmailAction,
        getUserInfoAction,
        logoutUserAction,
        userInfo,
        error,
        newUser,
        showModalAction,
        setGeneralErrorAction,
        setCaptchaTokenAction,
        captchaTokenSignup
    } = props;


    const isEmailValid = useMemo(() => validateEmail(email), [email]);
  
    useEffect(() => {
        userInfo.geozilla_user_id && setAFCustomerId(userInfo.geozilla_user_id);
    }, [userInfo]);


    useLoadScripts({ script: FB, check: 'FB' });
    useLoadScripts({ script: GGL.script, src: GGL.src, check: 'GoogleAuth' });

    useEffect(() => {
        if (access_token) {
            loginUserSocAction({ driver, access_token });
        }
    }, [access_token]);

    useEffect(() => {
        if (logined) {
            getUserInfoAction();
        }
    }, [logined]);


    useEffect(() => {
        if (isEmailValid) {
            verifyGoogleToken(setCaptchaTokenAction, 'signup');
        }
    }, [isEmailValid]);

    const onApplyClick = () => {

        if (captchaTokenSignup) {
            return registerUserEmailAction();
        }

        else {
            setGeneralErrorAction({ type: 'email', show: true});
        }
    };

    const onEmailChange = e => {
        setEmailAction(e.target.value);
    }


    return (
        <Fragment>
            <Modal>
                <SigninForm />
            </Modal>
            {logined && userInfo ? (
                renderAuth(t, driver, userInfo, logoutUserAction)
            ) : (
                <Fragment>
                    <p className={style.login__title}>{t('joinVia')}</p>
                    <div className={style.login__options}>
                        {(!logined || driver === 'email') && (
                            <div className={style.login__form}>
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    className={addStyle(
                                        styleEmailAuth,
                                        err,
                                        error.type === 'login' && error.show
                                    )}
                                    placeholder={t('email')}
                                    value={email}
                                    onChange={onEmailChange}
                                />
                                <button className={style.login__apply} onClick={onApplyClick}>{t('apply')}</button>

                                {newUser ? (
                                    <div className={style.login__signup_err}>
                                        {t('errors.errorExist')}
                                        <p>
                                            <button
                                                className={style.login__signin_btn}
                                                onClick={showModalAction}
                                            >{t('signIn')}</button>
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        <div className={style.login__options_soc}>
                            {!logined && <p className={style.login__separator}>{t('or')}</p>}
                            <div className={style.login__soc_auth}>
                                {(!logined || driver === 'facebook') && (
                                    <button
                                        className={addStyle(
                                            styleFBAuth,
                                            err,
                                            error.type === 'login' && error.show
                                        )}
                                        onClick={loginFB(setParamsAction)}
                                    >
                                        <img src={facebook} alt="fb" />
                                    </button>
                                )}
                                {(!logined || driver === 'google') && (
                                    <button
                                        className={addStyle(
                                            styleGGLAuth,
                                            err,
                                            error.type === 'login' && error.show
                                        )}
                                        onClick={loginGGL(setParamsAction)}
                                    >
                                        <img src={google} alt="google" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

const mapStateToProps = ({ user, error, main }) => {
    return {
        ...user,
        error,
        ...main
    };
};

const mapDispatchToProps = {
    loginUserSocAction,
    setParamsAction,
    registerUserEmailAction,
    setEmailAction,
    getUserInfoAction,
    logoutUserAction,
    showModalAction,
    setGeneralErrorAction,
    setCaptchaTokenAction
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login));
