import main from './main';
import user from './user';
import popup from './popup';
import error from './error';
import checkout from './checkout';
import steps from './steps';
import seo from './seo';

export default {
    main,
    user,
    popup,
    error,
    checkout,
    steps,
    seo
};
