import { createReducer } from '../common';
import {
    LOGIN_USER,
    SET_PARAMS_USEER,
    LOGIN_USER_EMAIL,
    SET_EMAIL_USER,
    SET_PASSWORD_USER,
    REGISTER_USER_EMAIL,
    SHOW_PASSWORD,
    GET_USER_INFO,
    LOGOUT_USER,
    RESET_PASSWORD,
    SET_APPSFLYER_ID
} from 'actions/user';

const initState = {
    logined: false,
    token: '',
    driver: '',
    access_token: '',
    email: '',
    password: '',
    passwordType: 'password',
    passwordReset: false,
    newUser: false,
    userInfo: {},
    appsflyerId: null,
};

const login = (state, { payload }) => {
    const { access_token: token, entity } = payload;
    return {
        ...state,
        logined: true,
        token,
        newUser: !!entity,
        userInfo: {
            ...entity,
        },
    };
};

const setParams = (state, { payload }) => {
    return {
        ...state,
        ...payload,
    };
};

const setEmail = (state, { payload }) => {
    let driver = '';

    if (payload) {
        driver = 'email';
    }

    return {
        ...state,
        driver,
        email: payload,
    };
};

const setPassword = (state, { payload }) => {
    return {
        ...state,
        password: payload,
    };
};

const showPassword = (state, { payload }) => {
    return {
        ...state,
        passwordType: payload,
    };
};

const setUserInfo = (state, { payload }) => {
    return {
        ...state,
        userInfo: {
            ...payload,
        },
    };
};

const logoutUser = (state, { payload }) => {
    return {
        ...initState,
    };
};

const resetPassword = (state, { payload }) => {
    const { passwordReset } = state;
    return {
        ...state,
        passwordReset: !passwordReset,
        ...payload,
    };
};

const setNewUser = (state, { payload }) => {
    return {
        ...state,
        newUser: true,
        ...payload,
    };
};


const setAppsflyerId = (state, { payload }) => {
    return {
        ...state,
        appsflyerId: payload,
    };
};

const handlers = {
    [LOGIN_USER.SUCCESS]: login,
    [SET_PARAMS_USEER]: setParams,
    [LOGIN_USER_EMAIL.SUCCESS]: login,
    [REGISTER_USER_EMAIL.SUCCESS]: login,
    [REGISTER_USER_EMAIL.FAILURE]: setNewUser,
    [SET_EMAIL_USER]: setEmail,
    [SET_PASSWORD_USER]: setPassword,
    [SHOW_PASSWORD]: showPassword,
    [GET_USER_INFO.SUCCESS]: setUserInfo,
    [LOGOUT_USER.SUCCESS]: logoutUser,
    [RESET_PASSWORD.SUCCESS]: resetPassword,
    [LOGOUT_USER.FAILURE]: logoutUser,
    [SET_APPSFLYER_ID]: setAppsflyerId,
};

export default createReducer(initState, handlers);
