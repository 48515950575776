// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotFound-style__notfound--2flNT{width:280px;margin:0 auto;text-align:center;min-height:100vh}@media screen and (min-width: 768px){.NotFound-style__notfound--2flNT{width:80%}}.NotFound-style__notfound--2flNT svg{width:100%}.NotFound-style__notfound__heading--IlrtY{position:relative;margin:20px 0;text-align:center;font-weight:700;font-style:normal;font-size:182px;line-height:150px;color:#00bfa5}.NotFound-style__notfound__text--13pGV{margin-top:24px;text-align:center;font-weight:300;font-size:18px;line-height:28px;color:#323232}.NotFound-style__notfound__text--13pGV>span{color:#07c0b1}\n", ""]);
// Exports
exports.locals = {
	"notfound": "NotFound-style__notfound--2flNT",
	"notfound__heading": "NotFound-style__notfound__heading--IlrtY",
	"notfound__text": "NotFound-style__notfound__text--13pGV"
};
module.exports = exports;
