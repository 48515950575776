import React from "react";

const ErrorImg = () => (
  <svg
    width="374"
    height="153"
    viewBox="0 0 374 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.08 121.514H0V94.4633L61.8029 0H96.8745V94.4633H116.335V121.514H96.8745V152H66.08V121.514ZM66.08 42.0791H65.6523L32.0777 94.4633H66.08V42.0791Z"
      fill="#00BFA5"
    />
    <path
      d="M323.745 121.514H257.665V94.4633L319.468 0H354.54V94.4633H374V121.514H354.54V152H323.745V121.514ZM323.745 42.0791H323.317L289.743 94.4633H323.745V42.0791Z"
      fill="#00BFA5"
    />
    <path
      d="M245.08 57.2547C245.08 88.8756 212.978 135.02 187.825 152.277C163.879 135.02 130.571 88.8756 130.571 57.2547C130.571 25.6337 156.204 0 187.825 0C219.446 0 245.08 25.6337 245.08 57.2547Z"
      fill="#00BFA5"
    />
    <path
      d="M237.835 50.5985C237.835 73.5097 219.037 92.0829 195.847 92.0829C172.658 92.0829 153.859 73.5097 153.859 50.5985C153.859 27.6873 172.658 9.11414 195.847 9.11414C219.037 9.11414 237.835 27.6873 237.835 50.5985Z"
      fill="#E6FFFC"
    />
    <path
      d="M195.847 26.6411C195.847 28.577 194.278 30.1465 192.342 30.1465H131.505C129.569 30.1465 128 28.577 128 26.6411C128 24.7052 129.569 23.1357 131.505 23.1357H192.342C194.278 23.1357 195.847 24.7052 195.847 26.6411ZM201.613 9.11414H180.581C178.645 9.11414 177.075 10.6836 177.075 12.6195C177.075 14.5554 178.645 16.1249 180.581 16.1249H201.613C203.549 16.1249 205.119 14.5554 205.119 12.6195C205.119 10.6836 203.549 9.11414 201.613 9.11414ZM239.004 48.8419H217.972C216.036 48.8419 214.466 50.4113 214.466 52.3472C214.466 54.2831 216.036 55.8526 217.972 55.8526H239.004C240.94 55.8526 242.509 54.2831 242.509 52.3472C242.509 50.4113 240.94 48.8419 239.004 48.8419Z"
      fill="#65C8BA"
    />
    <path
      d="M137.348 30.1463H131.505C129.569 30.1463 128 28.5768 128 26.6409C128 24.705 129.569 23.1355 131.505 23.1355H137.348C135.412 23.1355 133.842 24.705 133.842 26.6409C133.842 28.5768 135.412 30.1463 137.348 30.1463Z"
      fill="#2C887C"
    />
    <path
      d="M187.825 114.509C156.559 114.509 131.146 89.4464 130.58 58.3146C143.749 53.3297 150.639 45.935 156.679 45.6922C159.092 45.5952 165.88 49.4993 173.423 54.4169C193.326 67.3924 216.554 74.3758 240.312 74.2845C240.403 74.2842 240.492 74.284 240.58 74.284H242.505C235.255 97.5887 213.516 114.509 187.825 114.509Z"
      fill="#65C8BA"
    />
    <path
      d="M159.468 57.2546C159.468 56.2867 160.253 55.5019 161.221 55.5019C162.189 55.5019 162.973 56.2867 162.973 57.2546C162.973 58.2226 162.189 59.0073 161.221 59.0073C160.253 59.0073 159.468 58.2226 159.468 57.2546ZM161.221 78.6763C162.189 78.6763 162.973 77.8915 162.973 76.9236C162.973 75.9556 162.189 75.1709 161.221 75.1709C160.253 75.1709 159.468 75.9556 159.468 76.9236C159.468 77.8915 160.253 78.6763 161.221 78.6763ZM169.428 67.2148C170.396 67.2148 171.181 66.4301 171.181 65.4621C171.181 64.4942 170.396 63.7094 169.428 63.7094C168.46 63.7094 167.675 64.4942 167.675 65.4621C167.675 66.4301 168.46 67.2148 169.428 67.2148ZM148.758 88.102C145.25 79.2981 146.162 69.2951 150.965 61.3106C151.272 61.5742 151.671 61.7338 152.107 61.7338C153.075 61.7338 153.859 60.9491 153.859 59.9811C153.859 59.312 153.485 58.7306 152.933 58.4352C155.805 54.73 159.627 51.6254 164.335 49.5203C164.572 49.4142 164.801 49.3156 165.021 49.2249C161.179 47.0294 158.133 45.6336 156.679 45.6922C150.639 45.9352 143.749 53.3297 130.58 58.3146C130.779 69.2715 134.056 79.4769 139.583 88.1022H148.758V88.102Z"
      fill="#2C887C"
    />
    <path
      d="M222.058 63.702C229.546 63.6176 237.04 64.6604 244.283 66.8269C239.728 93.8909 216.184 114.509 187.825 114.509C163.897 114.509 143.398 99.8311 134.84 78.9878L134.866 79.0018C153.619 88.8861 176.423 86.8693 193.162 73.863C201.438 67.4329 211.579 63.82 222.058 63.702Z"
      fill="#AEE2DB"
    />
    <path
      d="M230.377 64.073C227.483 64.4329 224.765 65.3485 222.177 66.6985C222.809 66.9633 223.253 67.5877 223.253 68.3157C223.253 69.2836 222.468 70.0684 221.5 70.0684C220.532 70.0684 219.747 69.2836 219.747 68.3157C219.747 68.2442 219.752 68.1738 219.76 68.1047C214.043 71.7757 208.923 77.4979 203.854 83.8331C204.268 84.1537 204.535 84.6555 204.535 85.2196C204.535 86.1875 203.75 86.9723 202.782 86.9723C202.365 86.9723 201.982 86.8265 201.681 86.5834C200.804 87.7054 199.926 88.8395 199.045 89.9785C196.678 93.0352 194.284 96.1288 191.806 99.1145C192.068 99.4208 192.227 99.8188 192.227 100.254C192.227 101.222 191.442 102.007 190.474 102.007C190.141 102.007 189.83 101.914 189.565 101.753C185.782 106.097 181.777 110.103 177.369 113.283C177.268 113.356 177.167 113.428 177.067 113.5C180.551 114.162 184.148 114.509 187.826 114.509C216.185 114.509 239.728 93.891 244.284 66.8271C239.726 65.4637 235.069 64.5455 230.377 64.073ZM202.782 102.007C201.814 102.007 201.029 101.222 201.029 100.254C201.029 99.286 201.814 98.5013 202.782 98.5013C203.75 98.5013 204.534 99.286 204.534 100.254C204.534 101.222 203.75 102.007 202.782 102.007ZM216.842 80.4289C215.874 80.4289 215.089 79.6442 215.089 78.6762C215.089 77.7082 215.874 76.9235 216.842 76.9235C217.81 76.9235 218.595 77.7082 218.595 78.6762C218.595 79.6442 217.81 80.4289 216.842 80.4289ZM229.032 78.6762C228.064 78.6762 227.279 77.8915 227.279 76.9235C227.279 75.9556 228.064 75.1708 229.032 75.1708C229.999 75.1708 230.784 75.9556 230.784 76.9235C230.784 77.8915 229.999 78.6762 229.032 78.6762Z"
      fill="#65C8BA"
    />
  </svg>
);

export default ErrorImg;
