import React, { Fragment, useEffect } from 'react';
import  { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '../../components/UI/Card/Card';
import Button from '../../components/UI/Button/Button';
import style from './Summary.style';
import classNames from 'classnames/bind';
import app from 'assets/app.png';
import { connect } from 'react-redux';
import { buyPlanAction } from 'actions/checkout';
import { setAppsflyerIdAction} from 'actions/user';
import Modal from '../../components/UI/ModalPopup/ModalPopup';
import Error from '../../components/UI/Error/Error';
import { withRouter } from 'react-router';
import { getCookie } from 'helper';



const Summary = (props) => {

    const {
        plan,
        isCouponApplied,
        discountedPrice,
        discountAmount,
        buyPlanAction,
        paymentType,
        setAppsflyerIdAction,
        done,
        history: { push },
    } = props;

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        done && push('/success');
    }, [done]);
    
    useEffect(() => {
        const cookiesId = getCookie('afUserId');
        setAppsflyerIdAction(cookiesId);
    }, []);



    const PLANS = {
        yearly: {
            title: t('yearlyPlanT.name'),
            price: t('yearlyPlanT.price'),
            period: t('yearlyPlanT.billingPeriod'),
            total: '47.99',
            btnID: 'ua-pay-btn'
        },

        monthly: {
            title: t('monthlyPlan.name'),
            discountAmount: '',
            price: t('monthlyPlan.price'),
            period: t('monthlyPlan.billingPeriod'),
            total: '9.99',
            btnID: 'ua-pay-btn'
        },

        quarterly: {
            title: t('quarterlyPlan.name'),
            discountAmount: '',
            price: t('quarterlyPlan.price'),
            period: t('quarterlyPlan.billingPeriod'),
            total: '29.99',
            btnID: 'ua-pay-btn'
        },
    };

    let paymentMethod;

    switch (paymentType) {
        case 'AndroidPayCard':
            paymentMethod = 'Google Pay';
            break;

        case 'PayPalAccount':
            paymentMethod = 'PayPal';
            break;

        case 'CreditCard':
            paymentMethod = t('card');
            break;

        case 'ApplePayCard' :
            paymentMethod = 'Apple Pay';
            break;

        default:
            paymentMethod = 'Unknown';
    }


    const renderSummary = (plan) => {
        if (plan === 'default') {
            return <Redirect to='/'/>;
        }
        
        return (
            <Fragment>
                <div
                    className={classNames(
                        style.summary__line,
                        style.summary__line_product
                    )}
                >
                    <div className={style.summary__product}>
                        <img src={app} className={style.summary__img} alt="Product icon" />
                        <p className={style.summary__name}>{PLANS[plan].title}</p>
                    </div>
                    <p className={style.summary__price}>{PLANS[plan].price + '' + PLANS[plan].period}</p>
                </div>

                {isCouponApplied && (
                    <div className={style.summary__line}>
                        <p>{t('discount')}</p>
                        <p
                            className={classNames(
                                style.summary__price,
                                style.summary__price_red
                            )}
                        >
              -${discountAmount}
                        </p>
                    </div>
                )}

                <hr className={style.summary__hr} />

                <div
                    className={classNames(style.summary__line, style.summary__line_pay)}
                >
                    <p>{t('paymentMethod')}</p>
                    <p>{paymentMethod}</p>
                </div>

                <hr className={classNames(style.summary__hr, style.summary__hr_long)} />
                <div className={classNames(style.summary__line, style.summary__total)}>
                    <p>{t('total')}</p>
                    <p>${isCouponApplied ? discountedPrice : PLANS[plan].total}</p>
                </div>

                <Modal>
                    <Error />
                </Modal>
            </Fragment>
        );
    };

    return (
        <div className="wrapper">
            <div className="container">
                <div className={style.summary}>
                    <p className={style.summary__title}>{t('orderSummary')}</p>
                    <Card type="summary">{renderSummary(plan)}</Card>
                    <Button onClick={buyPlanAction} type='checkout' id={PLANS[plan].btnID}>
                        {t('payNow')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ main, user: { logined }, checkout }) => {
    return {
        ...main,
        logined,
        ...checkout,
    };
};

const mapDispatchToProps = {
    buyPlanAction,
    setAppsflyerIdAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Summary));
