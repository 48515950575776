export const PLANS = {
    yearly: {
        name: 'yearlyPlanT.name',
        url: 'yearly',
        price: 'yearlyPlanT.price',
        period: 'yearlyPlanT.billingPeriod',
        clickId: 'click_to_pay_trial_47.99',
        billedAt: 'yearlyPlanT.billedAt',
        descr: 'yearlyPlanT.billingTerms'

    },

    monthly: {
        name: 'monthlyPlan.name',
        url: 'monthly',
        price: 'monthlyPlan.price',
        period: 'monthlyPlan.billingPeriod',
        clickId: 'click_to_pay_9.99',
        billedAt: 'monthlyPlan.billedAt',
        descr: 'monthlyPlan.billingTerms'
    },

    quarterly: {
        name: 'quarterlyPlan.name',
        url: 'quarterly',
        price: 'quarterlyPlan.price',
        period: 'quarterlyPlan.billingPeriod',
        clickId: 'click_to_pay_29.99',
        billedAt: 'quarterlyPlan.billedAt',
        save: 'Save 25%',
        descr: 'quarterlyPlan.billingTerms'
    },

    default: {
        name: '3-Day Free Trial',
        price: '$47.99',
        url: 'default',
    },
};

export const getProduct = () => {
    const plansNames = Object.keys(PLANS);

    const plan = plansNames.find((i) =>
        window.location.search.includes(PLANS[i].url)
    );

    if (plan) {
        return PLANS[plan];
    }
    return PLANS['default'];
};

export const selectPlanAndRoute = (action, push) => (plan) => () => {
    action(plan);
    push('/?' + plan);
};

export const NO_SUPPORT_CODES = [
    'FJ',
    'DZ',
    'KE',
    'SD',
    'IR',
    'GT',
    'NG',
    'PK',
    'GH',
    'LK',
    'MA',
    'BD',
    'IQ',
    'CM',
    'PH',
    'SO',
    'IN',
    'MN',
    'ET',
    'LC',
    'TZ',
    'AF',
    'SV',
    'ML',
    'MR',
    'SZ',
    'CR',
    'MM',
    'KH',
    'EG',
    'TR',
    'NP',
    'BW',
    'NA',
    'SL',
    'SB',
    'BI',
];

export const HELP_SCRIPT = `
(function(w,d){
  w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];
  function r(){var s=document.createElement('script');s.async=1;s.type='text/javascript';s.src='https://widget.helpcrunch.com/';(d.body||d.head).appendChild(s);}
  if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}
})(window, document);
    
    HelpCrunch('init', 'geozilla', {
    applicationId: 3,
    applicationSecret: 'Yo7grtnwrzHyfuQ6ffjoIykSGOJYG8ELyGXZ0BOQQpBJxHPCJheOMf/JqIEAzBrKkAohMFbwHu1KNIdOmK7l+A=='
  })

  HelpCrunch('showChatWidget');
`;
