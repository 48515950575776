// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/arrow-select.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Language-style__Select--wb7qT{cursor:pointer;width:auto;display:flex;justify-content:flex-start;align-items:center;height:30px}.Language-style__Select--wb7qT svg{width:20px;height:20px;margin:0}.Language-style__Select__input--1rWuw{height:100%;cursor:pointer;font-weight:600;color:#232323;text-decoration:none;font-size:14px;-webkit-appearance:none;appearance:none;border:none;background-color:transparent;margin:0 8px;padding-right:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:10px 10px;background-position:right center}.Language-style__Select__input--1rWuw:focus,.Language-style__Select__input--1rWuw:active,.Language-style__Select__input--1rWuw:hover{outline:none}\n", ""]);
// Exports
exports.locals = {
	"Select": "Language-style__Select--wb7qT",
	"Select__input": "Language-style__Select__input--1rWuw"
};
module.exports = exports;
